module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"9","matomoUrl":"https://s.fixmycity.de","siteUrl":"https://radschnellweg8-lb-wn.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Radschnellweg 8 | Der neue Radschnellweg von Ludwigsburg nach Waiblingen","short_name":"RS 8","start_url":"/","background_color":"white","display":"minimal-ui","legacy":false,"theme_color":"#e6007e","icon":"src/images/rsv8-logo.png","lang":"de-DE","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7ff4817209a0037d257b39a5ad30c91f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
